if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/sw.js", {scope: "/"}).then(function () {
    console.log("Service Worker Registered")
  })
}

document.addEventListener("turbolinks:load", () => {
  let deferredPrompt
  const installApp = document.querySelector(".install-app")
  const actionButton = installApp?.querySelector("#action-btn")

  if (installApp) installApp.style.display = "block"

  window.addEventListener("beforeinstallprompt", (e) => {
    deferredPrompt = e

    const prompt = async () => {
      if (deferredPrompt !== null) {
        deferredPrompt.prompt()
        const {outcome} = await deferredPrompt.userChoice
        if (outcome === "accepted") {

          if (installApp) installApp.style.display = "none"
          deferredPrompt = null
        }
      }

      actionButton.removeEventListener("click", prompt);
    }

    actionButton?.addEventListener("click", prompt)
  })
})
